import clsx from "clsx";
import type { ForwardedRef, HTMLAttributes } from "react";
import { forwardRef } from "react";

type Size = "small" | "medium" | "large" | "extraLarge";

export interface DisplayTextProps extends HTMLAttributes<HTMLElement> {
  element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div";
  size?: Size;
}

function DisplayTextComp(
  { element: Element = "p", size = "extraLarge", className, ...rest }: DisplayTextProps,
  ref: ForwardedRef<HTMLElement>
) {
  return (
    <Element
      ref={ref as any}
      {...rest}
      className={clsx(
        "m-0",
        "text-gray-900",
        size === "small" && ["text-base", "font-normal", "leading-normal"],
        size === "medium" && ["text-lg", "font-bold", "leading-snug"],
        size === "large" && ["text-xl", "font-bold", "leading-relaxed"],
        size === "extraLarge" && ["text-2xl", "font-black", "leading-loose", "sm:text-3xl", "md:text-4xl"],
        className
      )}
    />
  );
}

export const DisplayText = forwardRef(DisplayTextComp);
